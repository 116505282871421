import { colors } from 'colors/colors';
import styled from 'styled-components/macro';
import { animationShadow } from 'styling/animation';
import { flexAlign, toneColor } from 'styling/fast';

export const ButtonActive = styled.button<{ active?: boolean; tone?: string }>`
  width: 10rem;
  height: 10rem;
  border: 0;
  border-radius: 1rem;
  background-color: ${({ active, tone }) =>
    active ? toneColor(tone) : '#000000'};
  color: ${colors.PURPLE_DARK_BG};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  ${flexAlign}
  border: 0.2rem solid ${({ tone }) => toneColor(tone)};
  ${animationShadow('white', '10px', '4s')}
  :hover {
    background-color: ${({ tone }) => toneColor(tone)};
    color: ${colors.PURPLE_DARK_BG};
  }
`;
