import { Web3ReactProvider } from '@web3-react/core';
import { LanguageProvider } from 'context/Localization';
// import { RefreshContextProvider } from '@contextApi/RefreshContext';
import { getLibrary } from 'utils/web3React';
import store, { persistor } from 'state';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import MulticallUpdater from 'state/multicall/updater';
import { PopupProvider } from './Popup';
import PopupBar from 'components/Popup';
import ConfirmContextProvider from './Confirm';

interface Props {
  children: React.ReactNode;
}

function Updaters() {
  return (
    <>
      <MulticallUpdater />
    </>
  );
}

const Providers = (props: Props) => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID);
  console.log(chainId);
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ReduxProvider store={store}>
        <Updaters />
        <ToastContainer theme="colored" />
        <HelmetProvider>
          <PersistGate persistor={persistor}>
            <LanguageProvider>
              {/* <RefreshContextProvider> */}
              <ConfirmContextProvider>
                <PopupProvider>{props.children}</PopupProvider>
              </ConfirmContextProvider>
              {/* </RefreshContextProvider> */}
            </LanguageProvider>
          </PersistGate>
        </HelmetProvider>
      </ReduxProvider>
    </Web3ReactProvider>
  );
};

export default Providers;
