import { ENV } from 'config';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state';
import { SETBALANCE } from 'state/profile/action';
import { useIsLogin } from 'state/profile/hooks';
import { apiServer } from 'Api';

export default function useBalance() {
  const balanceRedux = useSelector((state: AppState) => state.profile.balance);
  const isLogin = useIsLogin();
  const [balance, setBalance] = useState<any>(null);
  const dispatch = useDispatch();

  const fetchBalance = useCallback(async () => {
    setBalance(null);
    const response = await apiServer.balance.get();
    console.log(response);
    if (response.success) {
      setBalance(response.data);
      dispatch(SETBALANCE(response.data));
    }
  }, []);

  useEffect(() => {
    if (isLogin) {
      fetchBalance();
    }
  }, [isLogin]);

  useEffect(() => {
    if (balance) {
      setBalance(balanceRedux);
    }
  }, [balanceRedux]);

  return [balance, fetchBalance];
}
