import Endpoint from '../Endpoint';

export default class LogsEndpointed extends Endpoint {
  async get(limit = null, skip = 0) {
    return await this.axiosWrapper
      .get(`/reward_log`, {
        params: {
          limit,
          skip,
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
