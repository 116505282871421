import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { AppState, useAppSelector } from 'state';

export const useToken = () => {
  const account = useAccount();
  const token = useAppSelector((state: AppState) => state.profile.token);
  if (account) {
    return token;
  }
};

export const useAccount = () => {
  const account = useAppSelector((state) => state.profile.account);
  return account.toLowerCase() || '';
};

export const useIsLogin = () => {
  const IsLogin = useAppSelector((state) => state.profile.islogin);
  return IsLogin;
};
