export const ENV = {
  API_SERVER: process.env.REACT_APP_API_GAME,
  API_SOCKET: process.env.REACT_APP_SOCKET_API,
  API_PUBLIC_SERVER: process.env.REACT_APP_API_PUBLIC,
  API_PUBLIC_SOCKET: process.env.REACT_APP_SOCKET_PUBLIC,
  API_SINGLE_SERVER: process.env.REACT_APP_SINGLE_SERVER,
  API_SINGLE_SOCKET: process.env.REACT_APP_SINGLE_SOCKET,
  MAX_NUMBER: process.env.REACT_APP_MAX_NUMBER,
};

export const nftDetails = {
  board:
    '2048 Board is designed for an in-game purpose. It is required to have numbers bonded to the board. Once bonded, it cannot be un-bonded except burn. Each board grade has its daily turn limit and it may consume energy token to play. Learn more on Litepaper.',
  number_generator: 'Number generator consume 2048 and produce number',
  energy_worker: 'Lynx: Dog Energizer is a very rare NFT that produce energy',
  cat: 'Gumi: Cat Power Up your 2048 point from playing the game',
};

export const nftInfo = {
  number_generator: [
    { name: 'Upgrade', info: 'Increase number production rate by 1.' },
    { name: 'Level', info: ' Higher level will have a lower upgrade cost.' },
    { name: 'Generate', info: 'Consume 2048-points in 1:1 per number.' },
  ],
  board: [
    { name: 'Level', info: 'Higher level will have more daily turns.' },
    { name: 'Upgrade', info: 'Unable' },
  ],
  energy_worker: [{ name: 'Usage', info: 'Generate 2048E (Energy Token).' }],
};

export const PathToNftDetail = {
  dog: '/energy_worker',
  cat: '/gumi_powerup',
  boardfull: '/board',
  board: '/board',
  gacha: '/number_generator',
  number_generator: '/number_generator',
  energy_worker: '/energy_worker',
};

export const nftName = {
  cat: 'Gumi: Cat Power Up',
  number_generator: 'Number Generator',
  board: 'Board',
  energy_worker: 'Lynx: Dog Energizer',
  number: 'Number',
};

export const nftNameImg = {
  cat: 'cat',
  number_generator: 'gacha',
  board: 'boardfull',
  energy_worker: 'dog',
  number: 'number',
};
