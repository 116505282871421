import { colors } from 'colors/colors';
import styled from 'styled-components';
import { toneBg, toneColor } from 'styling/fast';

export const InputTextWrapper = styled.div<{ tone?: string }>`
  width: 100%;
  box-sizing: border-box;
  font-size: 1.8rem;
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  input::placeholder {
    color: white;
  }
  input {
    transition: all 0.5s;
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 1rem;
    background-color: transparent;
    border: 0.2rem solid;
    border-color: ${({ tone }) =>
      tone ? toneColor(tone) : colors.PURPLE_LIGHT};
    color: ${({ tone }) => (tone ? toneColor(tone) : colors.PURPLE_LIGHT)};
  }
  input:hover {
    border: 0.2rem solid
      ${({ tone }) => (tone ? toneColor(tone) : colors.PURPLE_LIGHT)};
  }
  input:focus {
    border: 0.2rem solid
      ${({ tone }) => (tone ? toneColor(tone) : colors.PURPLE_LIGHT)};
    color: ${({ tone }) => (tone ? toneColor(tone) : colors.PURPLE_LIGHT)};
  }
  input:not(:placeholder-shown) {
    border: 0.2rem solid
      ${({ tone }) => (tone ? toneColor(tone) : colors.PURPLE_LIGHT)};
  }
  .max_length_place {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    span {
      font-size: 1.8rem;
      color: ${colors.RED_PROFIT};
    }
  }
  .max {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    color: white;
    cursor: pointer;
    align-self: flex-end;
  }
  .max_button {
    position: absolute;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    top: 1rem;
    font-size: 1.2rem;
    right: 1rem;
    background: ${({ tone }) => (tone ? toneBg(tone) : toneBg('blue'))};
  }
`;
