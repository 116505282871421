import { Address } from 'config/types';
import addresses from 'config/contracts';
import { ChainId } from '@2048/sdk';

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID;
  return address[97] ? address[chainId] : address[ChainId.MAINNET];
};

export const getMulticallAddress = (): string => {
  return getAddress(addresses['multicall']);
};
