import AppBox from 'components/AppBox';
import {
  FlexColumn,
  FlexRow,
  GridColumn,
  GridRow,
  TextH2,
  TextTitle,
} from 'components/box';
import { ButtonActive } from 'components/ButtonActive/ButtonActive';
import ButtonImg from 'components/ButtonImg';
import CountdownText from 'components/CountdownText';
import Loading from 'components/Layout/Loading';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import useBalance from 'hooks/useBalance';
import React, { useState } from 'react';
import { apiServer } from 'Api';
import { tommorrowUtc0 } from 'utils/date';
import { GenerateWrapper, GachaponWrapper } from './styled';
import NftAvatar from 'components/NftAvatar';
import useConfirm from 'hooks/useConfirm';
import usePopup from 'hooks/usePopup';
import NftTooltip from 'components/NftTooltip';
import BuyEnergyModal from './components/BuyEnergyModal';
import SellEnergyModal from './components/SellEnergyModal';
import Button from 'components/Button';

const levelMap = ['1', '2', '3', '4', '5'];

function EnergyTrade() {
  const [state, setState] = useState('');

  return (
    <FlexColumn style={{ alignItems: 'center' }}>
      <FlexRow
        style={{
          width: '100%',
          marginTop: 8,
          marginBottom: 16,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          text="BUY ENERGY"
          style={{ marginRight: '1rem', fontSize: '1.6rem' }}
          disabled={false}
          tone="blue"
          onClick={() => {
            setState('buy');
          }}
        ></Button>
        <Button
          style={{ fontSize: '1.6rem' }}
          text="SELL ENERGY"
          disabled={false}
          tone="gold"
          onClick={() => {
            setState('sell');
          }}
        ></Button>
      </FlexRow>
      <ModalPopup
        title={`Buy Energy`}
        tone={'blue'}
        show={state === 'buy'}
        onClose={() => {
          setState(null);
        }}
      >
        <BuyEnergyModal onClose={() => setState(null)} tone="blue" />
      </ModalPopup>
      <ModalPopup
        title={`Sell Energy`}
        tone={'gold'}
        show={state === 'sell'}
        onClose={() => {
          setState(null);
        }}
      >
        <SellEnergyModal onClose={() => setState(null)} tone="gold" />
      </ModalPopup>
    </FlexColumn>
  );
}

function Control({ have, setHave, level, setLevel }) {
  return (
    <GridRow className="control_container">
      <ButtonActive
        active={have}
        onClick={() => setHave(!have)}
        tone="blue"
        className="have_btn"
      >
        <TextH2>Active</TextH2>
      </ButtonActive>
      <FlexRow className="level_container">
        <TextH2 style={{ marginRight: '1rem' }}>Level</TextH2>
        <FlexRow className="level_button_container">
          <ButtonActive
            key={'levelall'}
            onClick={() => setLevel('all')}
            tone="blue"
            className="level_btn"
            active={level === 'all'}
          >
            <TextH2>All</TextH2>
          </ButtonActive>
          {levelMap.map((e) => (
            <ButtonActive
              key={'level' + e}
              onClick={() => setLevel(e)}
              tone="blue"
              className="level_btn"
              active={level === e}
            >
              <TextH2>{e}</TextH2>
            </ButtonActive>
          ))}
        </FlexRow>
      </FlexRow>
    </GridRow>
  );
}

function Gachapon({
  tokenId,
  level,
  have,
  generated,
  rate,
  remaining,
  energyPrice,
  refreshBalance,
  rechargeAt,
}) {
  const { isConfirmed } = useConfirm();
  const pop = usePopup();
  const generate = async () => {
    const confirm = await isConfirmed({
      text: `Enter amount`,
      input: true,
      type: 'number',
      title: 'Generate Energy',
      max: rate - generated,
    });

    if (confirm.confirm && confirm.value) {
      // pop.loading({ text: `Generate ${confirm.value} energys` });
      const result = await apiServer.energy.generate(tokenId, confirm.value);
      // pop.clear();
      if (result.success && result.data) {
        pop.success({
          title: 'Generate Energy',
          text: `Generate Success!`,
        });
      } else {
        pop.error({
          title: 'Upgrade',
          text: `Generate Failed! ${result.message}`,
        });
      }
      refreshBalance();
    }
  };

  const setPrice = async () => {
    const confirm = await isConfirmed({
      text: `Enter price per 1 energy`,
      input: true,
      type: 'number',
      title: 'Auto sell',
    });

    if (confirm.confirm && confirm.value) {
      const result = await apiServer.energy.setPrice(tokenId, confirm.value);
      if (result.success && result.data) {
        pop.success({
          title: 'Auto sell',
          text: `Set energy price success!`,
        });
      } else {
        pop.error({
          title: 'Auto sell',
          text: `Set energy price Failed! ${result.message}`,
        });
      }
      refreshBalance();
    }
  };

  const Complete = (
    <div>
      <FlexRow className="time_container">
        {energyPrice > 10000 ? (
          <ButtonImg text="Generate" disabled={false} onClick={generate} />
        ) : (
          <div>Auto sell for ${energyPrice}</div>
        )}
      </FlexRow>

      {/* <FlexRow className="time_container">
        <ButtonImg
          text="Enable Auto Sell"
          disabled={false}
          onClick={setPrice}
        />
      </FlexRow> */}
    </div>
  );

  const time = have ? new Date() : rechargeAt ?? tommorrowUtc0();

  return (
    <GachaponWrapper>
      <div className="topic">Level {level}</div>
      <NftAvatar className="gacha_img" type={'dog'} lv={level} />
      <TextH2>
        {generated} / {rate}
      </TextH2>
      <TextH2>Remaining: {remaining}</TextH2>
      <FlexRow>
        <CountdownText
          className="time_container"
          date={time}
          completeRender={Complete}
        />
        <NftTooltip placement={'top'} type={'energy_worker'} />
      </FlexRow>
    </GachaponWrapper>
  );
}

const initialValue = [
  { level: '1', have: '1', time: new Date('December 20, 2021 14:00:00 UTC') },
  { level: '2', have: '0', time: new Date('December 1, 2021 14:00:00 UTC') },
  { level: '3', have: '0', time: new Date('December 5, 2021 14:00:00 UTC') },
  { level: '4', have: '1', time: new Date('December 30, 2021 14:00:00 UTC') },
  { level: '5', have: '1', time: new Date('December 25, 2021 14:00:00 UTC') },
];

function Generate() {
  const [have, setHave] = useState(false);
  const [level, setLevel] = useState('all');
  const [state] = useState(initialValue);
  const [balance, refreshBalance] = useBalance();

  if (!balance) {
    return <Loading></Loading>;
  }

  let filter = balance.nft_ENERGY_WORKER.filter((e) => {
    let n = 1;
    if (level === 'all') {
      n = 1;
    } else {
      // console.log(e.level, level);
      n = e.star == level ? 1 : 0;
    }
    if (have === true) {
      n = e.generated < e.rate ? 1 : 0;
    }
    return n;
  });

  console.log(balance);

  return (
    <GenerateWrapper>
      <TextTitle>Dog Energizer</TextTitle>
      <AppBox>
        <EnergyTrade></EnergyTrade>

        <Control
          have={have}
          setHave={setHave}
          level={level}
          setLevel={setLevel}
        />
        <GridColumn className="gacha_container">
          {filter.map((e) => (
            <Gachapon
              key={`gacha-${e.tokenId}`}
              tokenId={e.tokenId}
              level={e.star}
              have={e.generated < e.rate}
              // time={e.time}
              generated={e.generated}
              rate={e.rate}
              refreshBalance={refreshBalance}
              remaining={e.remaining}
              energyPrice={e.energyPrice}
              rechargeAt={e.rechargeAt}
            />
          ))}
        </GridColumn>
      </AppBox>
    </GenerateWrapper>
  );
}

export default Generate;
