import Endpoint from '../Endpoint';

export default class RankingEndpointed extends Endpoint {
  async getAll(mode, limit = null, skip = 0) {
    return await this.axiosWrapper
      .get(`/ranking_new/${mode}`, {
        params: {
          limit,
          skip,
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getAllOld(mode, limit = null, skip = 0) {
    return await this.axiosWrapper
      .get(`/2048competition1/api/public/ranking_new/${mode}`, {
        params: {
          limit,
          skip,
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getUser(address) {
    return await this.axiosWrapper
      .get(`/ranking_single/${address}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getUserOld(address) {
    return await this.axiosWrapper
      .get(`/2048competition1/api/public/ranking_single/${address}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
